import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 파일용량 관련 기능 클래스
 */
class AttFileBoard {

    /**
     * 생성자
     */
    constructor() {

    }

    /**
     * 파일용량 목록 조회
     * @returns 
     */
     getAttFileBoardList() {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.AttFileBiz';
            request.methodId = 'GetAttBoardList';
            
            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

}
export const attfileboard = new AttFileBoard();