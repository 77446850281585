<template>
    <div class="admin-content">
		<h3>파일 용량 모니터</h3>
		<div class="flex-wrap right">
			<div class="ml10">
				<div class="btn-wrap">
                    <button type="button" 
                    class="btn-box"
                    @click="methods.getAttFileBoardList">조회</button>
				</div>
			</div>
		</div>

        <!-- 총 개수 -->
		<div class="total-box">
			<p>Total <strong>{{state.dataTable.attFileBoardItems === null ? 0 : state.dataTable.attFileBoardItems.length}}</strong></p>
		</div>

        <DataTable class="p-datatable-sm" 
            columnResizeMode="fit"
            scrollHeight="497px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.attFileBoardItems"
            :paginator="true" 
            :rows="15"
            :resizableColumns="true" >

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="BoardCategory" header="게시판코드" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="BoardName" header="게시판명칭" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="AttFileCount" header="첨부파일개수" :sortable="true" style="text-align:right; width:130px;" headerClass="tc"></Column>
            <Column field="AttFileSize" header="첨부파일최대용량" :sortable="true" style="text-align:right; width:130px;" headerClass="tc"></Column>
            <Column field="UseYn" header="사용여부" :sortable="true" style="text-align:center; width:130px;" headerClass="tc"></Column>
            <Column field="CreateDate" header="생성일" :sortable="true" style="text-align:center; width:130px;" headerClass="tc"></Column>

        </DataTable>
    </div>

</template>

<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { attfileboard } from '@/modules/system/PCM_SM0630E.js';
import { useStore } from 'vuex';

export default {
    components: {
    },
    setup() {

        // 스토어
        const store = useStore();
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 파일용량모니터 목록
                attFileBoardItems: null,

            },

        });

        // 메서드
        const methods = {

            // 파일용량모니터 목록 조회
            getAttFileBoardList: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                attfileboard.getAttFileBoardList(state.dataTable.selectedSystemCode).then(result => {

                    state.dataTable.attFileBoardItems = result;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

        }

        // 이벤트 핸들러
        const eventHandler = {

            }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 파일용량모니터 초기조회
            methods.getAttFileBoardList();

        });

        return {
            state,
            attfileboard,
            methods,
            eventHandler,
        }
    }
}
</script>